import axios from "axios";
import Swal from "sweetalert2";

export const soalService = {
    deleteAllSoal,
}

export async function deleteAllSoal(idKompetensi, activeFlag, tipeSoal) {
    const transaction =
      activeFlag == 1 ? "Disable" : "Menghapus";
    return await Swal.fire({
      icon: "warning",
      title: "Perhatian!",
      text: `Apakah Anda yakin ${transaction} semua data soal ?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Iya`,
      denyButtonText: `Tidak`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          iconHtml: '<i class="fas fa-spinner fa-spin"></i>',
          title: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        return await axios({
          method: "delete",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            idKompetensi +
            "/soal/all",
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
          },
          data: {
            active_flag: activeFlag,
            tipe_soal: tipeSoal,
          },
        })
          .then((response) => {
            console.log(response);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              timer: 2000,
            });
            return true;
          })
          .catch((err) => {
            console.log(err);
            let errResponse = err.response.data;
            if (errResponse.meta.code == 403) {
              Swal.fire({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: errResponse.meta.message,
              });
              return 403;
            }
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              timer: 2000,
            });
            return 500;
          });
      }
      return false;
    });
}
